import { useEffect, useState } from "react";
import {
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Toolbar,
  useTheme,
  Menu,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../../redux/store";
import { fetchOffices, setOffice } from "../../redux/appSlice";
import { useSelector } from "react-redux";
import { IRoute } from "../types";
import { PRIMARY_COLOR } from "../../theme/defaultTheme";

interface SideNavProps {
  open: boolean;
  onToggleNav: () => any;
  drawerWidth: number;
  isMobile: boolean;
  routes: IRoute[];
}

function SideNav({ open, onToggleNav, drawerWidth, isMobile, routes }: SideNavProps): JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { offices, office } = useSelector((state: RootState) => state.app);

  const currentLocation = location.pathname;

  const [expanded, setExpanded] = useState<string[]>([]);

  const toggleNav = (key: string): void => {
    if (!expanded.includes(key)) {
      setExpanded([...expanded, key]);
    } else {
      setExpanded(expanded.filter((el) => el !== key));
    }
  };

  const onClickMenu = ({ key, path, isExternal, children }: IRoute): void => {
    if (path != null) {
      if (isMobile) {
        onToggleNav();
      }
      if (isExternal === true) {
        window.open(path, "_blank");
      } else {
        navigate(path);
      }
    } else if (children) {
      toggleNav(key ?? "");
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openx = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if ((offices?.length ?? 0) === 0) {
      dispatch(fetchOffices());
    }
  }, [dispatch, offices?.length]);

  return (
    <Drawer
      variant={isMobile ? "temporary" : "persistent"}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box", border: "none" },
      }}
      onClose={onToggleNav}
      open={open}
    >
      <Toolbar />
      <List component="nav" aria-labelledby="nested-list-subheader" sx={{ p: 1 }}>
        <OutlinedInput
          value={office?.name}
          size="small"
          color="primary"
          onClick={(e) => handleClick(e)}
          endAdornment={openx ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
          sx={{ mb: 1 }}
        />
        <Menu
          anchorEl={anchorEl}
          open={openx}
          defaultValue={office?.id}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              maxHeight: 200, // Limit menu height
              width: 200, // Set menu width
            },
          }}
        >
          {offices?.map((office, index) => (
            <MenuItem
              key={index}
              value={office.id}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                handleClose();
                dispatch(setOffice(office!));
              }}
              sx={{ fontSize: "0.875rem", py: 0.5 }} // Smaller font size and padding
            >
              {office.name}
            </MenuItem>
          ))}
        </Menu>
        {routes
          .filter((route) => !route.hidden)
          .map((route) => (
            <div key={route.key}>
              <ListItemButton
                onClick={() => {
                  onClickMenu(route);
                }}
                href={""}
                target={route.isDownload === true ? "_blank" : "_self"}
                selected={currentLocation === route.path}
                sx={{
                  my: 0.5, // Reduce margin
                  p: 1, // Reduce padding
                  borderRadius: 1,
                  "&.Mui-selected": { color: theme.palette.primary.main },
                }}
              >
                <ListItemIcon sx={{ minWidth: 0, mr: 1, color: PRIMARY_COLOR }}>
                  {route.icon}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body2" sx={{ fontSize: "0.875rem" }}> {/* Smaller font size */}
                      {route.label}
                    </Typography>
                  }
                />
                {(route.children?.length ?? 0) > 0 ? (
                  expanded.includes(route.key ?? "") ? (
                    <ExpandLess sx={{ fontSize: "1rem" }} /> // Smaller icon size
                  ) : (
                    <ExpandMore sx={{ fontSize: "1rem" }} /> // Smaller icon size
                  )
                ) : null}
              </ListItemButton>
              {route.children && (
                <Collapse in={expanded.includes(route.key ?? "")} timeout="auto" unmountOnExit>
                  {route.children
                    .filter((subRoute) => !subRoute.hidden)
                    .map((subRoute) => (
                      <List key={subRoute.path} component="div" disablePadding dense>
                        <ListItemButton
                          onClick={() => {
                            onClickMenu(subRoute);
                          }}
                          sx={{
                            p: 0.5, // Reduce padding
                            pl: 3, // Adjust left padding for nested items
                            "&.Mui-selected": {
                              color: theme.palette.primary.main,
                            },
                          }}
                          selected={currentLocation === subRoute.path}
                        >
                          <ListItemIcon sx={{ minWidth: 0, mr: 1, color: PRIMARY_COLOR }}>
                            {subRoute.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2" sx={{ fontSize: "0.875rem" }}> {/* Smaller font size */}
                                {subRoute.label}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    ))}
                </Collapse>
              )}
            </div>
          ))}
      </List>
      <Typography sx={{ mt: "auto", textAlign: "center", mb: 1 }} variant="body2">
        v {process.env.REACT_APP_VERSION}
      </Typography>
    </Drawer>
  );
}

SideNav.defaultProps = {
  isMobile: false,
};

export default SideNav;