import { createAsyncThunk, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getUserDetails, setUserDetails, setToken, getToken, set2faToken, removeToken, remove2faToken, get2faToken } from "../utils/helpers";
import { AuthResponse, Role, ValidateOtpResponse } from "../modules/Auth/types/Auth";
import { AuthRepository } from "../modules/Auth/repository/Auth";

export const logoutUser = createAsyncThunk(
    "auth/logout",
    async (_, { rejectWithValue }) => {
        try {
            const mfaToken = get2faToken();
            if (mfaToken) {
                await AuthRepository.invalidateToken(mfaToken); // Invalidate MFA token
            }
            removeToken();
            remove2faToken();
            setUserDetails(null);
            return true;
        } catch (error) {
            return rejectWithValue("Failed to logout"); // Handle errors
        }
    }
);

export interface IAuthUser {
    username: string;
    officeId: number;
    userId: number;
    staffId?: number;
    roles?: any[];
}

export interface AuthSlice {
    user?: IAuthUser;
    authLoading: boolean;
    token?: string;
    globalMsg?: string;
    isAuthenticated: boolean;
    roles?: Role[];
    mfaData?: LoginInfo;
}

const initialState: AuthSlice = {
    user: getUserDetails() as IAuthUser,
    authLoading: true,
    token: getToken(),
    isAuthenticated: false,
    roles: [],
};

export interface LoginInfo {
    auth?: AuthResponse;
    mfaData?: ValidateOtpResponse;
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<IAuthUser>) => {
            state.user = action.payload;
            setUserDetails(action.payload);
        },
        setAuthLoading: (state, action: PayloadAction<boolean>) => {
            state.authLoading = action.payload;
        },
        login2fa: (state, action: PayloadAction<LoginInfo>) => {
            console.log('Payload: ', action.payload)
            state.mfaData = { ...state.mfaData, ...action.payload };
            if (action.payload.auth?.base64EncodedAuthenticationKey) {
                setToken(action.payload.auth?.base64EncodedAuthenticationKey!);
            }
        },
        login: (state, action: PayloadAction<LoginInfo>) => {
            state.token = action.payload.auth?.base64EncodedAuthenticationKey;
            state.isAuthenticated = true;
            state.authLoading = false;
            state.mfaData = action.payload;
            const user: IAuthUser = {
                username: action.payload.auth?.username!,
                officeId: action.payload.auth?.officeId!,
                userId: action.payload.auth?.userId!,
                staffId: action.payload.auth?.staffId,
                roles: action.payload.auth?.roles!,
            };

            state.user = user;
            state.roles = action.payload.auth?.roles;

            setUserDetails(user);
            setToken(action.payload.auth?.base64EncodedAuthenticationKey!);
            if (action.payload.mfaData?.token) {
                set2faToken(action.payload.mfaData.token);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(logoutUser.pending, (state) => {
                state.authLoading = true; // Set loading state
            })
            .addCase(logoutUser.fulfilled, (state) => {
                state.user = undefined;
                state.mfaData = undefined;
                state.token = undefined;
                state.isAuthenticated = false;
                state.authLoading = false;
            })
            .addCase(logoutUser.rejected, (state, action) => {
                state.authLoading = false;
                state.globalMsg = action.payload as string; // Set error message
            });
    },
});

export const { setUser, setAuthLoading, login, login2fa } = authSlice.actions;

export default authSlice.reducer;