import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AppConfig from '../../../lib/AppConfig';
import { Staff } from '../types/responses/Staff';

interface StaffState {
    allStaff: Staff[];
    displayedStaff: Staff[];
    searchQuery: string;
    currentPage: number;
    pageSize: number;
    loading: boolean;
}

const initialState: StaffState = {
    allStaff: [],
    displayedStaff: [],
    searchQuery: '',
    currentPage: 0,
    pageSize: AppConfig.defaultPageSize,
    loading: false,
};

const staffSlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        setAllStaff(state, action: PayloadAction<Staff[]>) {
            state.allStaff = action.payload;
            state.displayedStaff = action.payload;
        },
        setSearchQuery(state, action: PayloadAction<string>) {
            state.searchQuery = action.payload;
        },
        setCurrentPage(state, action: PayloadAction<number>) {
            state.currentPage = action.payload;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.pageSize = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        updateDisplayedStaff(state) {
            // Filter staff based on search query
            const filteredStaff = state.allStaff.filter((staff) =>
                staff.displayName?.toLowerCase().includes(state.searchQuery.toLowerCase()) ||
                staff.officeName?.toLowerCase().includes(state.searchQuery.toLowerCase()) ||
                staff.firstname?.toLowerCase().includes(state.searchQuery.toLowerCase()) ||
                staff.lastname?.toLowerCase().includes(state.searchQuery.toLowerCase())
            );

            // Paginate the filtered staff
            const startIndex = state.currentPage * state.pageSize;
            const endIndex = startIndex + state.pageSize;
            state.displayedStaff = filteredStaff.slice(startIndex, endIndex);
        },
    },
});

export const {
    setAllStaff,
    setSearchQuery,
    setCurrentPage,
    setPageSize,
    setLoading,
    updateDisplayedStaff,
} = staffSlice.actions;
export default staffSlice.reducer;