import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../types/response/User';
import AppConfig from '../../../lib/AppConfig';

interface UserState {
    allUsers: User[];
    displayedUsers: User[];
    searchQuery: string;
    currentPage: number;
    pageSize: number;
    loading: boolean;
}

const initialState: UserState = {
    allUsers: [],
    displayedUsers: [],
    searchQuery: '',
    currentPage: 0,
    pageSize: AppConfig.defaultPageSize,
    loading: false,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAllUsers(state, action: PayloadAction<User[]>) {
            state.allUsers = action.payload;
            state.displayedUsers = action.payload; // Initially, display all users
        },
        setSearchQuery(state, action: PayloadAction<string>) {
            state.searchQuery = action.payload;
        },
        setCurrentPage(state, action: PayloadAction<number>) {
            state.currentPage = action.payload;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.pageSize = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        updateDisplayedUsers(state) {
            // Filter users based on search query
            const filteredUsers = state.allUsers.filter((user) =>
                user.username?.toLowerCase().includes(state.searchQuery.toLowerCase()) ||
                user.email?.toLowerCase().includes(state.searchQuery.toLowerCase()) ||
                user.firstname?.toLowerCase().includes(state.searchQuery.toLowerCase()) ||
                user.lastname?.toLowerCase().includes(state.searchQuery.toLowerCase())
            );

            // Paginate the filtered users
            const startIndex = state.currentPage * state.pageSize;
            const endIndex = startIndex + state.pageSize;
            state.displayedUsers = filteredUsers.slice(startIndex, endIndex);
        },
    },
});

export const {
    setAllUsers,
    setSearchQuery,
    setCurrentPage,
    setPageSize,
    setLoading,
    updateDisplayedUsers,
} = userSlice.actions;
export default userSlice.reducer;