import AppConfig from "../../../lib/AppConfig";
import { cleanApiError, handleResponse } from "../../../utils/handleResponse";
import { getAPIHeaders, getAuthAPIHeaders } from "../../../utils/helpers";
import { AuthResponse, RequestOtpResponse, ValidateOtpResponse } from "../types/Auth";

export namespace AuthRepository {
    // Login
    export async function login(username: string, password: string): Promise<AuthResponse> {
        try {
            let url = `${AppConfig.api_url}/fineract-provider/api/v1/authentication`;

            const headers = getAuthAPIHeaders()
            headers.set('Content-type', 'application/json')

            let response = await fetch(url, {
                method: 'POST',
                headers,
                body: JSON.stringify({ username, password })
            });

            const data = await handleResponse(response);
            return data;
        } catch (error: any) {
            console.log('Login failed');
            throw cleanApiError(error);
        }
    }

    // Check 2FA Methods
    export async function check2faMethods(): Promise<{ name: string, target: string }[]> {
        try {
            let url = `${AppConfig.api_url}/fineract-provider/api/v1/twofactor`;

            let response = await fetch(url, {
                headers: getAuthAPIHeaders()
            });

            const data = await handleResponse(response);
            return data;
        } catch (error: any) {
            console.log('Failed to fetch 2FA methods');
            throw cleanApiError(error);
        }
    }

    // Request OTP
    export async function requestOtp(deliveryMethod: { name: string, target: string }, extendedToken: boolean = false): Promise<RequestOtpResponse> {
        try {
            let url = `${AppConfig.api_url}/fineract-provider/api/v1/twofactor?deliveryMethod=${deliveryMethod.name}&extendedToken=${extendedToken}`;

            const headers = getAuthAPIHeaders()
            headers.set('Content-type', 'application/json')

            let response = await fetch(url, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    requestTime: Date.now(),
                    tokenLiveTimeInSec: 86400,
                    extendAccessToken: extendedToken,
                    deliveryMethod
                })
            });

            const data = await handleResponse(response);
            return data;
        } catch (error: any) {
            console.log('Failed to request OTP');
            throw cleanApiError(error);
        }
    }

    // Validate OTP
    export async function validateOtp(token: string): Promise<ValidateOtpResponse> {
        try {
            let url = `${AppConfig.api_url}/fineract-provider/api/v1/twofactor/validate?token=${token}`;

            const headers = getAuthAPIHeaders()
            headers.set('Content-type', 'application/json')

            let response = await fetch(url, {
                method: 'POST',
                headers,
            });

            const data = await handleResponse(response);
            return data;
        } catch (error: any) {
            console.log('Failed to validate OTP');
            throw cleanApiError(error);
        }
    }

    // InValidates OTP
    export async function invalidateToken(token: string): Promise<any> {
        try {
            let url = `${AppConfig.api_url}/fineract-provider/api/v1/twofactor/invalidate`;

            const headers = getAPIHeaders()
            headers.set('Content-type', 'application/json')

            let response = await fetch(url, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    token,
                })
            });

            const data = await handleResponse(response);
            return data;
        } catch (error: any) {
            console.log('Failed to validate OTP', error);
            return {}
        }
    }

    // Validate Password
    export async function validatePassowrd(
        username: string,
        password: string,
    ): Promise<AuthResponse> {
        try {
            const url = `${AppConfig.api_url}/fineract-provider/api/v1/authentication`;

            const headers = getAuthAPIHeaders()
            headers.set('Content-type', 'application/json')

            const response = await fetch(url, {
                method: "POST",
                headers,
                body: JSON.stringify({ username, password })
            });

            const data = await handleResponse(response);

            return data;
        } catch (error: any) {
            console.log("Failed to change password");
            throw cleanApiError(error);
        }
    }
}