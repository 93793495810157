import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  AppBar as muiAppBar,
  Box,
  IconButton,
  styled,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import PortalLogo from "../components/PortalLogo";
import SideNav from "./components/SideNav";
import UserMenu from "./components/UserMenu";
import useDashboardRoutes from "./dashboardRoutes";
import AutoLogout from "../components/core/AutoLogout";
import { CustomThemeContext } from "../theme/MyThemeProvider";

const drawerWidth = 220;

function DashboardLayout(): JSX.Element {
  const { themeName, toggleTheme } = useContext(CustomThemeContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { routes } = useDashboardRoutes()

  const toolbarHeight = !isMobile ? 56 : 64;

  const [open, setOpen] = useState(!isMobile);

  const AppBar = styled(muiAppBar)(({ theme }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }));

  const MainContent = styled("main")(({ theme }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: `${toolbarHeight}px`,
    paddingTop: "18px",
    ...(!isMobile &&
      open && {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.complex,
      }),
    }),
  }));

  useEffect(() => {
    if (!isMobile) {
      setOpen(true);
    }
  }, [isMobile]);

  return (
    <>
      <AutoLogout timeoutSeconds={1800} warningDurationSeconds={60} onLogout={window.logout} />
      <Box display="flex">
        <AppBar
          color="inherit"
          position="fixed"
          elevation={isMobile ? 1 : 0}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                aria-label="open drawer"
                onClick={() => {
                  setOpen(!open);
                }}
                edge="start"
                sx={{ mr: 2, ...(!isMobile && { display: "none" }) }}
              >
                {open ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
              <PortalLogo height={50} />
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>

              <IconButton color="primary" sx={{ p: 0 }} onClick={toggleTheme}>
                {themeName === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
              </IconButton>
              <IconButton color="primary" sx={{ p: 0 }}>
                <NotificationsIcon />
              </IconButton>
              <UserMenu />
            </Box>
          </Toolbar>
        </AppBar>
        <SideNav
          open={open}
          onToggleNav={() => {
            setOpen(!open);
          }}
          drawerWidth={drawerWidth}
          isMobile={isMobile}
          routes={routes.filter(route => !route.hidden)}
        />
        <MainContent sx={{ flexGrow: 1, pb: 6 }}>
          <Outlet />
        </MainContent>
      </Box>
    </>
  );
}

export default DashboardLayout;
