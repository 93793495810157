import secureLocalStorage from "react-secure-storage";
import AppConfig from "../lib/AppConfig";

export function setUserDetails(user: any) {
  secureLocalStorage.setItem("user", JSON.stringify(user));
}

export function getUserDetails() {
  try {
    const user = JSON.parse((secureLocalStorage.getItem("user") as string) ?? "null");
    return user;
  } catch (error) {
    return null;
  }
}

export function setToken(token: string) {
  secureLocalStorage.setItem("token", JSON.stringify(token));
}

export function set2faToken(token?: string) {
  secureLocalStorage.setItem("token2fa", JSON.stringify(token));
}

export function remove2faToken() {
  secureLocalStorage.removeItem("token2fa");
}

export function removeToken() {
  secureLocalStorage.removeItem("token");
}

export function getToken() {
  return (secureLocalStorage.getItem("token") as string)?.replaceAll("\"", "");
}

export function get2faToken() {
  return (secureLocalStorage.getItem("token2fa") as string)?.replaceAll("\"", "");
}

export function setThemeName(themeName: string) {
  secureLocalStorage.setItem("theme", themeName);
}

export function getThemeName() {
  return (secureLocalStorage.getItem("theme") as string) ?? "default";
}

export function getAuthAPIHeaders(): Headers {
  const headers = new Headers();
  headers.append("Authorization", `Basic ${getToken()}`.replaceAll("\"", ""));
  headers.append("fineract-platform-tenantid", AppConfig.tenantId);
  return headers;
}

export function getAPIHeaders(): Headers {
  const headers = getAuthAPIHeaders()
  const mfaToken = get2faToken()
  if (mfaToken) {
    headers.append("fineract-platform-tfa-token", mfaToken.replaceAll("\"", ""));
  }
  return headers;
}

export function bytesToKilobytes(bytes: any) {
  return (bytes / 1024).toFixed(2);
}

function jaccardSimilarity(arr1: string[], arr2: string[]): number {
  let set1 = new Set(arr1);
  let set2 = new Set(arr2);
  let intersection = new Set([...set1].filter(x => set2.has(x)));
  let union = new Set([...set1, ...set2]);
  return intersection.size / union.size;
}

function calculateNameSimilarity(name1: string, name2: string): number {
  // Convert names to lowercase and split into arrays of words
  let nameArray1 = name1.toLowerCase().split(' ').sort().map(name => name.trim());
  let nameArray2 = name2.toLowerCase().split(' ').sort().map(name => name.trim());

  // Calculate Jaccard similarity
  let similarity = jaccardSimilarity(nameArray1, nameArray2);

  // Convert similarity to percentage
  let similarityPercentage = similarity * 100;

  return similarityPercentage;
}

export function areNamesSimilar(name1: string, name2: string): boolean {
  let similarityPercentage = calculateNameSimilarity(name1, name2);
  return similarityPercentage > 90;
}

export function generateTransactionReceipt() {
  // Use the current time as a seed
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  // Generate a 10-character random string
  let randomString = '';
  for (let i = 0; i < 10; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  // Format the transaction receipt number
  const receiptNumber = `KBS${randomString}`;

  return receiptNumber;
}

export function generateRandomString(length: number = 10): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}
