import { useState, useEffect, lazy, type LazyExoticComponent, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingScreen from "./components/LoadingScreen";
import DashboardLayout from "./layout/DashboardLayout";
import FullPageLayout from "./layout/FullPageLayout";
import { useAppDispatch, type RootState } from "./redux/store";
import { logoutUser } from "./redux/authSlice";

function Loadable(Component: LazyExoticComponent<any>): JSX.Element {
	return (
		<Suspense fallback={<LoadingScreen />}>
			<Component />
		</Suspense>
	);
}

const Login = Loadable(lazy(() => import("./modules/Auth/Login")));
const MFA = Loadable(lazy(() => import("./modules/Auth/2FA")));
const EnterOtp = Loadable(lazy(() => import("./modules/Auth/EnterOTP")));
const ResetPass = Loadable(lazy(() => import("./modules/Auth/RenewPass")));
const MainDashboard = Loadable(lazy(() => import("./modules/Dashboard/MainDashboard")));
const CashierDashboard = Loadable(lazy(() => import("./modules/Dashboard/CashierDashboard")));

// Member pages
const MembersLanding = Loadable(lazy(() => import("./modules/Members/pages/landing")));
const MembersPage = Loadable(lazy(() => import("./modules/Members/pages/members")));
const ViewMember = Loadable(lazy(() => import("./modules/Members/pages/viewMember")));
const EditMember = Loadable(lazy(() => import("./modules/Members/pages/editMember")));
const MemberSaving = Loadable(lazy(() => import("./modules/Members/pages/memberSaving")));
const MemberSavingJournalEntries = Loadable(lazy(() => import("./modules/Members/pages/savingJournalEntries")));
const MemberSavingJournalTransaction = Loadable(lazy(() => import("./modules/Members/pages/savingJournalTransactions")));
const CloseMember = Loadable(lazy(() => import("./modules/Members/pages/closeMember")));
const MemberLoan = Loadable(lazy(() => import("./modules/Members/pages/memberLoan")));

// Loans pages
const ListLoans = Loadable(lazy(() => import("./modules/Loan/ListLoans")));
const AddLoanPage = Loadable(lazy(() => import("./modules/Loan/AddLoan")));

// Savings pages
const SavingsPage = Loadable(lazy(() => import("./modules/Savings/Savings")));
const AddMember = Loadable(lazy(() => import("./modules/Members/pages/addMember")));
const AddSavings = Loadable(lazy(() => import("./modules/Savings/AddSavings")));

// Tills and Tellers
const ListTills = Loadable(lazy(() => import("./modules/Tills/ListTills")));
const ListTellers = Loadable(lazy(() => import("./modules/Tellers/pages/tellers")));
const TellerInfo = Loadable(lazy(() => import("./modules/Tellers/pages/tellerInfo")));
const CashierInfo = Loadable(lazy(() => import("./modules/Tellers/pages/cashierInfo")));
const DepositSaving = Loadable(lazy(() => import("./modules/Tellers/pages/depositSaving")));
const WithdrawSaving = Loadable(lazy(() => import("./modules/Tellers/pages/withdrawSaving")));
const AddTransaction = Loadable(lazy(() => import("./modules/Tills/AddTransaction")));

// Staff and Users
const ListStaffs = Loadable(lazy(() => import("./modules/Staff/pages/staffs")));
const ListUsers = Loadable(lazy(() => import("./modules/Users/pages/listUsers")));
const ViewStaff = Loadable(lazy(() => import("./modules/Staff/pages/view")));

// Roles
const ListRoles = Loadable(lazy(() => import("./modules/Roles/pages/viewRoles")));
const ViewRole = Loadable(lazy(() => import("./modules/Roles/pages/viewRole")));

const Analytics = Loadable(lazy(() => import("./modules/Dashboard/Analytics")));

const Reports = Loadable(lazy(() => import("./modules/Reports/pages/reports")));
const ReportData = Loadable(lazy(() => import("./modules/Reports/pages/reportData")));
const BiReports = Loadable(lazy(() => import("./modules/Reports/pages/bireports")));

// Journal entries
const Transactions = Loadable(lazy(() => import("./modules/JournalEntries/pages/transactions")));
const Transaction = Loadable(lazy(() => import("./modules/JournalEntries/pages/transaction")));
const CreateJournalEntry = Loadable(lazy(() => import("./modules/JournalEntries/pages/create_journal")));

// Marker checker
const MarkerCheckerList = Loadable(lazy(() => import("./modules/MarkerCheckerV2/pages/List")));
const MarkerCheckerView = Loadable(lazy(() => import("./modules/MarkerCheckerV2/pages/View")));

// Audits
const AuditList = Loadable(lazy(() => import("./modules/Audits/pages/List")));
const AuditView = Loadable(lazy(() => import("./modules/Audits/pages/View")));

// Setttings
const Settings = Loadable(lazy(() => import("./modules/Settings/Settings")));

function ProtectedRoute({ children, user }: { children: JSX.Element; user: any }) {
	return user ? children : <Navigate to="/" replace />;
}

function Router(): JSX.Element {
	const dispatch = useAppDispatch();
	const [isInitialized, setInitialized] = useState(false);
	const { user } = useSelector((state: RootState) => state.auth);

	window.logout = async () => {
		try {
			await dispatch(logoutUser());
			console.log("Logged out successfully");
		} catch (error) {
			console.error("Logout failed", error);
		}
	};

	useEffect(() => {
		if (!isInitialized) {
			setInitialized(true);
		}
	}, [isInitialized]);

	if (!isInitialized) {
		return <LoadingScreen />;
	}

	return (
		<BrowserRouter>
			<Routes>
				{/* Public Routes */}
				<Route path="/" element={user ? <Navigate to="/dashboard" replace /> : <FullPageLayout />}>
					<Route path="" element={Login} />
					<Route path="/two-factor-auth" element={MFA} />
					<Route path="/enter-otp" element={EnterOtp} />
					<Route path="/renew-pass" element={ResetPass} />
				</Route>

				<Route path="settings" element={<ProtectedRoute user={user} children={<DashboardLayout />} />} >
					<Route path="" element={Settings} />
				</Route>

				{/* Protected Routes */}
				<Route
					path="members"
					element={<ProtectedRoute user={user} children={<DashboardLayout />} />}
				>
					<Route path="" element={MembersLanding} />
					<Route path="add" element={AddMember} />
					<Route path="edit" element={EditMember} />
					<Route path="close" element={CloseMember} />
					<Route path="list" element={MembersPage} />
					<Route path=":memberId" element={ViewMember} />
					<Route path=":memberId/savings/:savingId" element={MemberSaving} />
					<Route path=":memberId/savings/:savingId/journals" element={MemberSavingJournalEntries} />
					<Route path=":memberId/savings/:savingId/journals/:transactionId" element={MemberSavingJournalTransaction} />
					<Route path=":memberId/loans/:loanId" element={MemberLoan} />
				</Route>

				{/* Protect /dashboard route */}
				<Route
					path="dashboard"
					element={<ProtectedRoute user={user} children={<DashboardLayout />} />}
				>
					<Route path="" element={MainDashboard} />
					<Route path="cashier" element={CashierDashboard} />
				</Route>

				<Route path="loans" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={ListLoans} />
					<Route path="add" element={AddLoanPage} />
				</Route>

				<Route path="analytics" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={Analytics} />
				</Route>

				<Route path="savings" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={SavingsPage} />
					<Route path="add" element={AddSavings} />
				</Route>

				<Route path="tills" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={ListTills} />
					<Route path="add-transaction" element={AddTransaction} />
				</Route>

				<Route path="tellers" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={ListTellers} />
					<Route path=":tellerId" element={TellerInfo} />
					<Route path=":tellerId/cashiers/:cashierId" element={CashierInfo} />
					<Route path="deposit" element={DepositSaving} />
					<Route path="withdraw" element={WithdrawSaving} />
				</Route>

				<Route path="staff" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={ListStaffs} />
					<Route path=":staffId" element={ViewStaff} />
				</Route>

				<Route path="roles" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={ListRoles} />
					<Route path=":roleId" element={ViewRole} />
				</Route>

				<Route path="reports" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={Reports} />
					<Route path=":reportId" element={ReportData} />
				</Route>

				<Route path="bireports" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={BiReports} />
				</Route>

				<Route path="users" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={ListUsers} />
				</Route>

				<Route path="transactions" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={Transactions} />
					<Route path=":txId" element={Transaction} />
					<Route path="create_gl" element={CreateJournalEntry} />
				</Route>

				<Route path="markercheckers" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={MarkerCheckerList} />
					<Route path=":id" element={MarkerCheckerView} />
				</Route>

				<Route path="audits" element={<ProtectedRoute user={user} children={<DashboardLayout />} />}>
					<Route path="" element={AuditList} />
					<Route path=":id" element={AuditView} />
				</Route>

				<Route path="*" element={<Navigate to="/dashboard" />} />
			</Routes>
		</BrowserRouter>
	);
}

export default Router;
